import React from "react"
import Layout from "../components/Layout"
import {PageWrapper, PageContainer} from "../styles/GlobalStyles";
import styled from "styled-components";
import Seo from "../components/SEO";
import RulesConstant from "../constants/rules"
import {StaticImage} from "gatsby-plugin-image";

const RulesPage = () => {
  return (
      <Layout>
          <Seo title="Cornhole Žaidimo Taisyklės - Bohabo Cornhole Lietuva"
               description="Kaip žaisti Cornhole žaidimą su maišeliais? Pristatome Cornhole žaidimo taisykles - pasiruošimas žaidimui, žaidimo eiga bei taškų skaičiavimas."
          />
          <PageWrapper>
              <PageContainer>

                  <RulesConstant/>

                  <RulesInfoWrapper>
                      <RulesText>
                      <h3>Pasiruošimas žaidimui</h3>
                          <p>Žaidimui Jums reikės lygaus, plokščio pagrindo, ant kurio galėtumėte padėti žaidimo lentą.
                              Tai gali būti vėja, automobilių stovėjimo aikštelė, paplūdimys ar bet koks kitas lygus
                              paviršius. Lentą pastatykite tiesiai priešais save <b>8 m. atstumu</b>. Jeigu žaidžiate su
                              vaikais, mažinkite atstumą iki 5 m. Cornhole galima žaisti 1 x 1 arba 2 x 2.</p>

                          <RulesImgWrapper>
                              <StaticImage src="../assets/images/board-distance.png" placeholder="none" quality="50" alt="board-distance-image"/>
                          </RulesImgWrapper>

                          <p>Kiekvienam žaidėjui/komandai priklauso po 4 vienodos spalvos maišelius. Maišelis yra
                              pagamintas iš skirtingų medžiagų - viena maišelio pusė yra švelni, o kita - šiurkštesnė.
                              Švelni pusė turės geresnį sukibimą su lenta, nei šiurkšti. Ši maišelio savybė leis Jums
                              išbandyti skirtingas taktikas žaidžiant Cornhole žaidimą.</p>

                          <RulesSvgWrapper>
                              <StaticImage src="../assets/images/bags-sides-explained.png" placeholder="none" quality="50" alt="bag-sides-explained"/>
                          </RulesSvgWrapper>

                      <h4>Žaidimo eiga</h4>
                          <p>Akmuo, žirklės, popierius padės Jums nuspręsti, kam atitenka pirmenybė pradėti žaidimą. </p>
                          <p>Žaidžiant 1 x 1, žaidimą pradėkite stovint vienas šalia kito - vienas žaidėjas iš kairės, o
                              kitas – iš dešinės. Po kiekvieno raundo apsikeiskite pusėmis. 2 x 2 žaidime, stovėjimo
                              pozicija išlieka analogiška, tačiau pusėmis keičiamasi kas antrą raundą. Žaidžiant 2 x 2,
                              vieno raundo metu visus metimus atlieka tik vienas iš komandos narių. Pasibaigus raundui,
                              komandos žaidėjai apsikeičia ir metimus atlieka kitas komandos narys.</p>
                          <p>Žaidimas pradedamas metant po vieną maišelį, tuomet tą patį pakartoja priešininkų komanda
                              ir taip tęsiama iki kol išmetami visi maišeliai. Pabaigus raundą suskaičiuojami taškai.
                              Jeigu per šį raundą uždirbote daugiausiai taškų, kitą raundą pradėsite pirmieji. Jei per
                              raundą abi komandos nesurinko taškų, kitą raundą turi pradėti prieš tai buvusio raundo
                              nugalėtojas/ai.</p>
                      <h4>Taškų skaičiavimas</h4>
                          <p>Po kiekvieno raundo žaidėjai suskaičiuoja savo taškus. Daugiau taškų tame raunde surinkęs
                              žaidėjas ar komanda bendrame rezultate gauna tiek taškų, koks buvo skirtumas tarp
                              rezultato, t. y. kiek tame raunde surinko daugiau taškų nei varžovas/ai. Jeigu raundo
                              taškų skaičius lygus, prie bendro rezultato taškų neprisideda nei vienas žaidėjas/komanda.
                              Pavyzdžiui, jei surinkote 5 taškus, o priešininkas 3, prie bendro žaidimo rezultato
                              prisidėsite 2 taškus, o Jūsų komanda kitame raunde pradės pirmoji. Jei tiek Jūs, tiek Jūsų
                              varžovas surinkote po 6 taškus, taškai nebus pridedami nei vienai komandai.</p>
                          <RulesSvgWrapper>
                              <StaticImage src="../assets/images/points-minus-system.png" placeholder="none" quality="50" alt="points-minus-system"/>
                          </RulesSvgWrapper>
                          <RulesSvgWrapper>
                              <StaticImage src="../assets/images/points-cornhole.png" placeholder="none" quality="50" alt="points-cornhole"/>
                          </RulesSvgWrapper>


                      <h4>Žaidimo pabaiga</h4>
                          <p>Bendru sutarimu galite žaisti iki 11 arba 21 taško. Žaidimas laimimas 2 arba daugiau taškų persvara.</p>

                      </RulesText>

                  </RulesInfoWrapper>

              </PageContainer>
          </PageWrapper>
      </Layout>
  )
}

export default RulesPage


const RulesInfoWrapper = styled.div`
  display: grid;
  grid-gap: 3rem;
  margin-top: 0rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
      margin-top: 4rem;
  }
`;

const RulesText = styled.div`
    max-width: 750px;
    margin: auto;
`;

const RulesSvgWrapper = styled.div`
  grid-column: 1/-1;
  margin-top: 2rem;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
      margin-top: 3rem;
  }
`;

const RulesImgWrapper = styled.div`
  grid-column: 1/-1;
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
      margin-top: 2rem;
      margin-bottom: 2rem;
  }
`;