import React from 'react';
import {HeadingH2, PageContent} from "../styles/GlobalStyles";
import {Underline} from "../styles/Underline";
import {ButtonA} from "../styles/Button";
import {StaticImage} from "gatsby-plugin-image";
import styled from "styled-components";
import DownloadBohaboRules from '../assets/pdf/bohabo_cornhole_rules_lt.pdf'

const RulesConstant = () => {

    return (
        <PageContent>
            <RulesItems>
                <HeadingH2 >Taisyklės</HeadingH2>
                <Underline fluid primary small/>
                <RulesP>Nepaisant to, kad šį sparčiai populiarėjantį žaidimą galima žaisti įvairiais būdais, pagrindinis
                    žaidimo tikslas išlieka vienas – pataikyti kuo daugiau maišelių į lentoje esančią skylę. Norėdami,
                    kad greičiau ir lengviau suprastumėte ir perprastumėte žaidimo esmę, parengėme paprastas ir lengvai
                    įsimenamas Bohabo Cornhole žaidimo taisyklės.</RulesP>
                <ButtonA href={DownloadBohaboRules} rel="noindex nofollow" download big="true">Atsisiųskite Taisykles</ButtonA>
            </RulesItems>
            <RulesImg>
                    <StaticImage src="../assets/images/bohabo-lentos.png"  placeholder="none" quality="100" alt="Bohabo rules hero image"/>
            </RulesImg>
        </PageContent>
    );
};

export default RulesConstant;

const RulesItems = styled.div`
    grid-column: 1/-1;  
    text-align: center;
    align-self: center;
    justify-self: center;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-column: 1/7;
    text-align: left;
    padding-right: 5rem;
  }
`;

const RulesP = styled.p`
  color: ${props => props.theme.black};
  font-size: clamp(14px, 3vw, 18px);
  margin: 0 auto 2rem;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    margin: 4rem 0 5rem;
  }
`;

const RulesImg = styled.div`
  grid-column: 1/-1;
  justify-self: center;
  width: 100%;
  max-width: 500px;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-row: 1/1;
    grid-column: 7/13;
    transform: translateX(4px);
    max-width: 100%;
  }
`;
